import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import axios from 'axios';

//  axios.defaults.baseURL = 'http://localhost:5007';
axios.defaults.baseURL = 'https://api.allie.com.br';
// axios.defaults.baseURL = 'https://apicaixa.allie.com.br';
// axios.defaults.baseURL = 'https://api.allie360.com.br';

//  axios.defaults.envURL = 'http://localhost:3000';
axios.defaults.envURL = 'https://app.allie.com.br';
// axios.defaults.envURL = 'https://caixa.allie.com.br';
// axios.defaults.envURL = 'https://allie360.com.br';

axios.interceptors.request.use(function (config) {
	let loginData = JSON.parse(localStorage.getItem('login-data'));
	let token = '';

	if (loginData != null) {
		token = loginData.token;
		config.headers.common.Authorization = `Bearer ${token}`;
        config.headers.common.AllieUser = loginData.allie_user;
        config.headers.common.UserId = loginData.login;
		config.headers.common.UserCode = loginData.user_id;
	}

	let token_websocket = JSON.parse(localStorage.getItem('allie.com.br.websocket'));
	if (token_websocket != null) {
		token = token_websocket.token;
		config.headers.common.TokenWebsocket = `${token}`;
	}

	return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
