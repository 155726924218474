import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ContestationEmail = React.lazy(() => import('./views/pages/ContestationEmail/ContestationEmail'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const RegisterMobile = React.lazy(() => import('./views/pages/register-mobile/RegisterMobile'))
const Success = React.lazy(() => import('./views/pages/Success/Success'))

const ProfileMobile = React.lazy(() => import('./views/pages/ProfileMobile/ProfileMobile'))

const Verify = React.lazy(() => import('./views/pages/verify/Verify'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'))
const NewPassword = React.lazy(() => import('./views/pages/new-password/NewPassword'))

const Visualizador360 = React.lazy(() => import('./views/Visualizador360/Visualizador360'));
const Visualizador360Mobile = React.lazy(() => import('./views/Visualizador360Mobile/Visualizador360Mobile'));
const Viewer360Diff = React.lazy(() => import('./views/Viewer360Diff/Viewer360Diff'));
const Viewer360DiffMobile = React.lazy(() => import('./views/Viewer360DiffMobile/Viewer360DiffMobile'));

const TrimbleConfigure = React.lazy(() => import('./views/TrimbleConfigure/TrimbleConfigure'));


class App extends Component {

	constructor(props) {
		super(props);

		let loginData = JSON.parse(localStorage.getItem('login-data'));

		if(loginData == null) {
			loginData = {
				ok: false,
				name: ''
			}
		}

		this.state = {
			loggedIn: loginData.ok,
			userName: loginData.name
		};

		this.updateLoggedState = this.updateLoggedState.bind(this);
	}

    componentDidMount() {

        let loginData = JSON.parse(localStorage.getItem('login-data'));

		if((loginData == null || loginData.user_id == null) && 
            !(window.location.href.includes('login') || 
              window.location.href.includes('register') || 
              window.location.href.includes('password') || 
              window.location.href.includes('forgot') || 
              window.location.href.includes('sharing') ||
              window.location.href.includes('verify'))){

            let link = window.location.href.split('#')[1]
            if (!!link && !link.includes('sharing')) {
                window.location.href = `/#/login?goback=${link}`
            } else {
                window.location.href = `/#/login`
            }
		}
    }

	updateLoggedState(prop) {

		let loggedIn = prop.data.ok;

		this.setState({
			loggedIn: loggedIn
		});

		if (loggedIn) {
			window.location = '/#/my-projects'
		}
	}

    render() {
        return (
            <HashRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route exact path="/contestation" name="Contestação" element={<ContestationEmail />} />
                        <Route exact path="/login" name="Login Page" element={<Login updateLoggedState={this.updateLoggedState}/>} />
                        <Route exact path="/register" name="Register Page" element={<Register />} />
                        <Route exact path="/register-mobile" name="Register Mobile Page" element={<RegisterMobile />} />
                        <Route exact path="/verify" name="Verificação de email" element={<Verify />} />
                        <Route exact path="/404" name="Page 404" element={<Page404 />} />
                        <Route exact path="/500" name="Page 500" element={<Page500 />} />
                        <Route exact path="/visualizador-360" name="Visualizador Panorâmico" element={<Visualizador360 />}/>
                        <Route exact path="/viewer-360-mobile" name="Visualizador Panorâmico" element={<Visualizador360Mobile />}/>
                        <Route exact path="/visualizador-diff" name="Visualizador Panorâmico" element={<Viewer360Diff />}/>
                        <Route exact path="/visualizador-diff-mobile" name="Visualizador Panorâmico" element={<Viewer360DiffMobile />}/>
                        <Route exact path="/trimble-configure" name="Trimble Configure" element={<TrimbleConfigure />}/>
                        <Route exact path="/forgot" name="Esqueci minha senha" element={<Forgot />} />
                        <Route exact path="/new-password" name="Nova senha" element={<NewPassword />} />
                        <Route exact path="/profile-mobile" name="Meu Perfil" element={<ProfileMobile />} />
                        <Route exact path="/success" name="Sucesso" element={<Success />} />
                        <Route path="*" name="Home" element={<DefaultLayout />} />
                    </Routes>
                </Suspense>
            </HashRouter>
        )
    }
}

export default App
